<script>
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import { formatDollar } from '/~/utils/format/money'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import {
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import CheckoutPay from './components/pay/checkout-pay.vue'
import CheckoutSummary from './components/summary/checkout-summary.vue'

export default {
  name: 'purchase-checkout',
  components: {
    CheckoutSummary,
    CheckoutPay,
  },
  setup() {
    const { eWallets, fetchPaymentMethods } = usePaymentMethods()
    const { verifyingCard, verifyCard } = useVerifyCard()
    const {
      cart,
      cartIsEmpty,
      fetchCart,
      fetchCartOrderAddresses,
      cartOrderAddresses,
    } = useCart()
    const {
      pay,
      payment,
      total,
      readyToPay,
      submitting,
      payWithCard,
      selectedCard,
      transactionFees,
      loadingFees,
      multipleSourcesSelected,
      resetMethods,
    } = useCheckout()
    const { addresses, getAddresses } = useAddresses()
    const { fetchCurrentPurchases } = usePurchases()
    const { isUserAddressEnabled } = useProvider()
    const { fetchBalance: fetchPointsBalance } = usePoints()

    return {
      payment,
      cart,
      eWallets,
      fetchPaymentMethods,
      verifyingCard,
      verifyCard,
      cartIsEmpty,
      fetchCart,
      fetchCartOrderAddresses,
      cartOrderAddresses,
      pay,
      total,
      readyToPay,
      submitting,
      payWithCard,
      selectedCard,
      transactionFees,
      loadingFees,
      multipleSourcesSelected,
      formatDollar,
      addresses,
      getAddresses,
      fetchCurrentPurchases,
      resetMethods,
      ui,
      isUserAddressEnabled,
      fetchPointsBalance,
    }
  },
  data() {
    return {
      scrollTop: 0,
      isLoading: false,
      transition: {
        'enter-class': '',
        'enter-active-class': 'absolute',
      },
    }
  },
  computed: {
    billingAddress() {
      const {
        addresses = [],
        cartOrderAddresses: { billing },
      } = this

      return addresses.find((address) => address.id === billing?.id)
    },
    payDisabled() {
      return (
        !this.readyToPay || (this.isUserAddressEnabled && !this.billingAddress)
      )
    },
    processing() {
      return this.verifyingCard || this.payment.submitting || this.loadingFees
    },
  },
  watch: {
    $route(to, from) {
      this.transition =
        from.meta.index < to.meta.index
          ? {
              'enter-class': 'translate-x-full opacity-0',
              'enter-active-class': 'absolute',
              'leave-active-class': 'absolute -translate-x-full opacity-0',
            }
          : {
              'enter-class': '-translate-x-full opacity-0',
              'enter-active-class': 'absolute',
              'leave-active-class': 'absolute translate-x-full opacity-0',
            }
    },
    cartIsEmpty(isEmpty) {
      if (isEmpty) this.redirect()
    },
  },
  created() {
    this.fetchInitialData().then(() => {
      if (this.cartIsEmpty) {
        this.redirect()
      }
    })
  },
  methods: {
    onScroll(e) {
      if (e.target) {
        this.scrollTop = e.target.scrollTop
      }
    },
    redirect() {
      const splitHash = this.$route.hash.split('/')
      const hash = splitHash?.[0]

      if (hash === '#cart-checkout-confirmation') {
        return
      }

      this.$router.replace({ name: 'home' })

      return this.$notify({
        text: 'Your cart is currently empty',
        type: 'warning',
      })
    },
    async fetchInitialData() {
      this.isLoading = true
      this.resetMethods()
      this.fetchPointsBalance()
      const promises = [
        this.fetchCart(),
        this.getAddresses(),
        this.fetchCartOrderAddresses(),
      ]

      await Promise.all(promises)
        .then(([, addresses]) => {
          if (this._isDestroyed) return
          if (this.cart.count && !addresses.length) {
            this.$router.push({ hash: '#profile-add-address' })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async onSubmit() {
      try {
        await this.payment.checkout({
          address: this.billingAddress,
          onComplete: this.onComplete,
        })
      } catch (error) {
        this.$notify({
          text: error,
          type: 'error',
          duration: 5000,
        })
      }
    },
    async onComplete(result) {
      try {
        await this.fetchCurrentPurchases()
        this.fetchCart()
        this.fetchPaymentMethods()

        // Add small delay before update activity, as item might not be included into response yet
        setTimeout(() => emitter.emit('activity:refresh'), 2000)

        this.$router.push({
          name: 'purchase-checkout-confirmation',
          params: { orderId: result.number, fromCart: true, isError: false },
        })
      } catch (error) {
        if (error.errors) {
          this.$router.push({
            name: 'purchase-checkout-payment-failed',
            query: { errors: error.errors, message: error.message },
          })
        }
      }
    },
  },
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div
      class="flex flex-1 flex-col px-5 sm:flex-row sm:justify-between sm:overflow-hidden sm:px-0 md:pl-[30px]"
    >
      <div
        class="relative flex shrink-0 flex-col overflow-hidden sm:w-1/2 sm:rounded-t-3xl sm:bg-dark"
      >
        <div
          class="pointer-events-none absolute top-0 left-0 z-10 block h-[30px] w-full shadow-inner transition duration-300 sm:rounded-tl-3xl md:rounded-t-3xl"
          :class="scrollTop > 20 ? 'opacity-100' : 'opacity-0'"
        />
        <div
          class="h-full py-6 sm:overflow-auto sm:overflow-x-hidden"
          @scroll="onScroll"
        >
          <router-view v-if="ui.mobile" :is-loading="isLoading" />
          <div v-else class="relative mx-auto flex max-w-xl flex-nowrap">
            <transition v-bind="transition">
              <router-view
                :is-loading="isLoading"
                class="w-full shrink-0 transform transition duration-500 sm:px-5"
              />
            </transition>
          </div>
        </div>
        <div class="flex justify-center py-6 md:pr-[72px] md:pl-5 lg:px-[72px]">
          <checkout-pay
            :is-loading="isLoading"
            :processing="processing"
            :pay-disabled="payDisabled"
            :total="total"
            @pay-button-clicked="onSubmit"
          />
        </div>
      </div>

      <div class="flex shrink-0 flex-col sm:w-1/2">
        <checkout-summary :loading="isLoading" />
      </div>
    </div>
  </div>
</template>
